#joinUs {
    margin-left: 35px;
}
#joinUs .myjob{
    width: 100%;
    border-bottom:1px dashed #979797;
    margin-bottom:23px;
}
#joinUs .myjob:nth-last-of-type(1){
    border:none;
}
#joinUs .post{
    font-size:20px;
    color:#004894;
    letter-spacing:-0.22px;
    text-align:left;
}
#joinUs .require{
    font-size:18px;
    color:#333333;
    letter-spacing:-0.22px;
    text-align:left;
    margin-bottom:15px;
}
#joinUs .requireList>li{
    margin-left:20px;
    margin-bottom:10px;
    list-style:disc;
    font-size:18px;
    color:#333333;
    letter-spacing:-0.22px;
    text-align:left;
}
#joinUs .recruit{
    font-size:18px;
    color:#333333;
    letter-spacing:-0.22px;
    text-align:left;
    height: 60px;
    line-height: 60px;
    margin-bottom:21px;
}
#joinUs .recruit button{
    width: 140px;
    height: 50px;
    background: #004894;
    font-size: 20px;
    float: right;
}
#joinUs .pagination{
    width: 100%;
    margin:0 auto;
    height:auto;
    display:flex;
    justify-content: flex-end;
    margin-bottom:77px;
    margin-top:27px;
}
#joinUs .ant-pagination-item-active{
    background:#2a8ff6 !important;
}
#joinUs .ant-pagination-item-active a{
    color:#ffffff !important;
}
/* 加入我们弹窗 */
#joinUs .modal-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
#joinUs .modal-box .modal-content {
    width: 758px;
    height: 480px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
}
#joinUs .modal-box .modal-top {
    height: 65px;
    background: #004894;
    line-height: 65px;
    text-align: center;
    font-size: 22px;
    color:#ffffff;
}
#joinUs .modal-box .modal-btm {
    flex: 1;
    background: #fff;
}
#joinUs .modal-box .modal-btm > p {
    text-align: center;
    font-size:18px;
    color:#004894;
    letter-spacing:-0.22px;
    line-height: 45px;

}
#joinUs .modal-box .modal-btm > span {
    display: inline-block;
    width: 100%;
    text-align: center;
}
#joinUs .modal-box .modal-item {
    display: flex;
    justify-content: center;
    margin-top: 18px;
}
#joinUs .modal-box .modal-item > span {
    font-size:18px;
    color:#333333;
    letter-spacing:-0.22px;
    text-align: justify;
    width: 80px;
    font-weight: 600;
    margin-right: 20px;
}
#joinUs .modal-box .modal-item > span i {
    display: inline-block;
    width: 100%;
}
#joinUs .modal-box .modal-item input {
    width: 283px;
    height: 38px;
    border-radius: 20px;
}
#joinUs .modal-box .modal-item.code-box input {
    width: 100px;
}
#joinUs .modal-box .modal-item.code-box > div > span {
    padding: 0 20px;
}
#joinUs .modal-box .modal-item.code-box > div {
    width: 283px;
}
#joinUs .modal-box .modal-item > button {
    width: 170px;
    height: 38px;
    margin-right: 20px;
    border-radius: 20px;
}
#joinUs #file {
    display: none
}
#joinUs .upload-content {
    width: 283px;
    position: relative;
    display: flex;
}
#joinUs .upload-content .ant-progress {
    position: absolute;
    left: 0;
    top: 0;
    width: 92px;
    height: 92px;
    z-index: 2;
    background: #fff !important;
}
#joinUs .upload-content .ant-progress-inner {
    width: 100% !important;
    height: 100% !important;   
}
#joinUs .upload-content .preview-upload {
    position: absolute;
    width: 92px;
    height: 92px;
    left: 0;
    top: 0;
    background: #fff;
    border: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    word-break: break-all;
    overflow-y: auto;
}
#joinUs .upload-content .upload-tip {
    flex: 1;
    text-align: center;
}
#joinUs .upload-content .upload-tip button {
    margin-top: 10px;
}
#joinUs .upload-content .upload-tip button span {
    color: #fff;
}
#joinUs .upload-content .preview-upload i {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px;
    cursor: pointer;
}
#joinUs .upload-content label {
    width: 92px;
    height: 92px;
    text-align: center;
    line-height: 94px;
    border: 1px solid #e1e1e1;
    display: inline-block;
    cursor: pointer;
    position: relative;
}
#joinUs .upload-content label i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%)
}
#joinUs .upload-content p {
    display: inline-block;
}
#joinUs .upload-content span {
    font-size:16px;
    color:#999999;
    letter-spacing:-0.2px;
}

#item-box {
    width: 842px;
    border:1px solid #e5e5e5;
    height: 80px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    float: right;
  }
  #item-box span.item-pointer{
    width: 12px;
    height: 12px;
    background: #999;
    margin-left: 25px;
    margin-right: 16px;
    display: block;
  }
  #item-box li {
    font-size: 16px;
    color:#666666;
    letter-spacing:-0.2px;
    text-align:left;
  }
  #item-box li.liInfo {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
  }
  
  #item-box li.infoTime{
    font-size: 16px;
    color:#999999;
    letter-spacing:-0.2px;
    text-align:left;
    margin-right: 20px;
  }
  #item-box:hover {
    background: #004894;
  }
  #item-box:hover  li {
    color: #ffffff;
  }
  #item-box:hover span {
    background: #fff;
  }
