#mein .content-box {
  width: 100%;
  min-width: 1440px;
}
#mein .content{
  width:1280px;
  height: auto;
  margin:0 auto;
  display:flex;
  justify-content: space-between
}
#mein .content > div:nth-child(2) {
  flex: 1;
  padding-left: 35px;
  overflow: hidden;
}