
@font-face {font-family: "iconfont";
  src: url('iconfont.eot?t=1545635492085'); /* IE9*/
  src: url('iconfont.eot?t=1545635492085#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('data:application/x-font-woff;charset=utf-8;base64,d09GRgABAAAAAARsAAsAAAAABrQAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABHU1VCAAABCAAAADMAAABCsP6z7U9TLzIAAAE8AAAARAAAAFY8hkgZY21hcAAAAYAAAABLAAABcOc4tdpnbHlmAAABzAAAALcAAAC8qRtx32hlYWQAAAKEAAAALwAAADYT2pNFaGhlYQAAArQAAAAgAAAAJAgNA69obXR4AAAC1AAAAAgAAAAICC0AAGxvY2EAAALcAAAABgAAAAYAXgAAbWF4cAAAAuQAAAAgAAAAIAEPAEFuYW1lAAADBAAAAUUAAAJtPlT+fXBvc3QAAARMAAAAIAAAADHc39hweJxjYGRgYOBikGPQYWB0cfMJYeBgYGGAAJAMY05meiJQDMoDyrGAaQ4gZoOIAgCKIwNPAHicY2BkEWecwMDKwMHUyXSGgYGhH0IzvmYwYuRgYGBiYGVmwAoC0lxTGByeCTwTYG7438AQw9zA0AAUZgTJAQDnGgwaeJxjYGBgZWBgYAZiHSBmYWBgDGFgZAABP6AoI1icmYELLM7CoARWwwISfybw/z+MBPJZwCQDIxvDKOABkzJQHjisIJiBEQBQCgmpAHicY2BmYPjfwKLH3MDAzSDOYMLAoGrGYK7HqM7HyC7GwGzHaC7HKA5k6zEyAkkYWx0orgZWwmIupgJUzsQuzCgqwrqptW0ziwij6EymRC/PJCamJE+vRMalAnICi4pYOB7dvfuQjTFhsoxiTRcjP2Py0iWMKXxMi5U4GN1uN69nZl7f/OjfASYpryRGxiSgbkam5H/nRNQFuE2ExR1YmBxFhQx5FTV53FxTPJiYvJI8PRkA9D8m+AB4nGNgZGBgAOKHqlwn4/ltvjJwszCAwA235UsQ9P8GFj3mBiCXg4EJJAoALCQKmgB4nGNgZGBgbvjfwBDDosvA8P8/ix4DUAQFMAEAeawExAQAAAAELQAAAAAAAABeAAAAAQAAAAIANQADAAAAAAACAAAACgAKAAAA/wAAAAAAAHicZY9NTsMwEIVf+gekEqqoYIfkBWIBKP0Rq25YVGr3XXTfpk6bKokjx63UA3AejsAJOALcgDvwSCebNpbH37x5Y08A3OAHHo7fLfeRPVwyO3INF7gXrlN/EG6QX4SbaONVuEX9TdjHM6bCbXRheYPXuGL2hHdhDx18CNdwjU/hOvUv4Qb5W7iJO/wKt9Dx6sI+5l5XuI1HL/bHVi+cXqnlQcWhySKTOb+CmV7vkoWt0uqca1vEJlODoF9JU51pW91T7NdD5yIVWZOqCas6SYzKrdnq0AUb5/JRrxeJHoQm5Vhj/rbGAo5xBYUlDowxQhhkiMro6DtVZvSvsUPCXntWPc3ndFsU1P9zhQEC9M9cU7qy0nk6T4E9XxtSdXQrbsuelDSRXs1JErJCXta2VELqATZlV44RelzRiT8oZ0j/AAlabsgAAAB4nGNgYoAALgbsgImRiZGZgT0lMy+9PDWTgQEAFD0DBA==') format('woff'),
  url('iconfont.ttf?t=1545635492085') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('iconfont.svg?t=1545635492085#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dingwei:before { content: "\e610"; }

