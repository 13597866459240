#details {
    margin-left: 35px;
  }
  #details h3.title {
    font-size:20px;
    color:#333333;
    letter-spacing:-0.2px;
  }
  #details .publish-time {
    font-size:14px;
    color:#999999;
    letter-spacing:-0.2px;
    line-height: 25px;
    margin: 0;
  }
  #details .publish-time span:first-child {
    margin-right: 20px;
  } 
  #details .publish-time span:last-child {
    color: rgb(97, 169, 81)
  }
  #details .line-dashed {
    height: 1px;
    border-top: 1px dashed #979797;
    margin-top: 27px;
  }
  #details .details-content {
    padding: 30px 0;
  }
  #details .back-btn {
    text-align: center;
    padding: 60px 0;
  }
  #details .back-btn button {
    width: 118px;
    height: 40px
  }
  #details .details-content img {
    max-width: 100% !important;
  }


  #joinUs {
    margin-left: 35px;
}
 .myjob{
    width: 100%;
    border-bottom:1px dashed #979797;
    margin-bottom:23px;
}
 .myjob:nth-last-of-type(1){
    border:none;
}
 .post{
    font-size:20px;
    color:#004894;
    letter-spacing:-0.22px;
    text-align:left;
}
 .require{
    font-size:18px;
    color:#333333;
    letter-spacing:-0.22px;
    text-align:left;
    margin-bottom:15px;
}
 .requireList>li{
    margin-left:20px;
    margin-bottom:10px;
    list-style:disc;
    font-size:18px;
    color:#333333;
    letter-spacing:-0.22px;
    text-align:left;
}
.recruit{
    font-size:18px;
    color:#333333;
    letter-spacing:-0.22px;
    text-align:left;
    height: 60px;
    line-height: 60px;
    margin-bottom:21px;
}

/* 加入我们弹窗 */
 .modal-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
 .modal-box .modal-content {
    width: 758px;
    height: 480px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
}
 .modal-box .modal-top {
    height: 65px;
    background: #004894;
    line-height: 65px;
    text-align: center;
    font-size: 22px;
    color:#ffffff;
}
.modal-box .modal-btm {
    flex: 1;
    background: #fff;
}
.modal-box .modal-btm > p {
    text-align: center;
    font-size:18px;
    color:#004894;
    letter-spacing:-0.22px;
    line-height: 45px;

}
 .modal-box .modal-btm > span {
    display: inline-block;
    width: 100%;
    text-align: center;
}
 .modal-box .modal-item {
    display: flex;
    justify-content: center;
    margin-top: 18px;
}
.modal-box .modal-item > span {
    font-size:18px;
    color:#333333;
    letter-spacing:-0.22px;
    text-align: justify;
    width: 80px;
    font-weight: 600;
    margin-right: 20px;
}
 .modal-box .modal-item > span i {
    display: inline-block;
    width: 100%;
}
 .modal-box .modal-item input {
    width: 283px;
    height: 38px;
    border-radius: 20px;
}
 .modal-box .modal-item.code-box input {
    width: 100px;
}
 .modal-box .modal-item.code-box > div > span {
    padding: 0 20px;
}
 .modal-box .modal-item.code-box > div {
    width: 283px;
}
 .modal-box .modal-item > button {
    width: 170px;
    height: 38px;
    margin-right: 20px;
    border-radius: 20px;
}
 #file {
    display: none
}
 .upload-content {
    width: 283px;
    position: relative;
    display: flex;
}
 .upload-content .ant-progress {
    position: absolute;
    left: 0;
    top: 0;
    width: 92px;
    height: 92px;
    z-index: 2;
    background: #fff !important;
}
 .upload-content .ant-progress-inner {
    width: 100% !important;
    height: 100% !important;   
}
 .upload-content .preview-upload {
    position: absolute;
    width: 92px;
    height: 92px;
    left: 0;
    top: 0;
    background: #fff;
    border: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    word-break: break-all;
    overflow-y: auto;
}
 .upload-content .upload-tip {
    flex: 1;
    text-align: center;
}
 .upload-content .upload-tip button {
    margin-top: 10px;
}
 .upload-content .upload-tip button span {
    color: #fff;
}
 .upload-content .preview-upload i {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px;
    cursor: pointer;
}
 .upload-content label {
    width: 92px;
    height: 92px;
    text-align: center;
    line-height: 94px;
    border: 1px solid #e1e1e1;
    display: inline-block;
    cursor: pointer;
    position: relative;
}
 .upload-content label i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%)
}
 .upload-content p {
    display: inline-block;
}
 .upload-content span {
    font-size:16px;
    color:#999999;
    letter-spacing:-0.2px;
}
  