
/* 相同的标题 */
#home  .home-same-top {
  height: 44px;
  display: flex;
}
#home  .home-same-top p:first-child{
  border-bottom: 3px solid #e50012;
  font-size:20px;
  color:#333333;
  letter-spacing:1.11px;
  font-weight: 600;
  margin: 0;
}
#home  .home-same-top p:last-child{
  border-bottom: 1px solid #dedede;
  font-size:14px;
  color:#999999;
  padding-left: 13px;
  line-height: 28px;
  margin: 0;
  flex: 1;
}
/* banner图 */
#home .carousel {
  width: 100%;
  height: 520px;
  overflow: hidden;
  position: relative;
  min-width: 1440px;
}
#home .carousel .ant-carousel{
  height: 100%;
  width: 100%;
  background: #364d79;
  /* overflow: hidden; */
}
#home .carousel .ant-carousel .carousel-img-content {
  vertical-align: bottom;
}
#home .carousel .lbt-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  height: 50px;
  width: 1300px;
  left: 50%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

#home .news-banner-img{
  width: 800px !important;
  height: 500px  !important;
}

#home .carousel .lbt-btn img {
  opacity: 0.7;
}
#home .carousel .lbt-btn img:hover {
  opacity: 1;
}
#home .carousel .carousel-text {
  width: 600px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
#home .carousel .carousel-text  h1 {
  font-size:72px;
  color:#ffffff;
  margin: 0;
}

#home .carousel .carousel-text .carousel-text-btns a{
  margin:0 16px;
}
#home .carousel .carousel-text > p{
  font-size:24px;
  color:#ffffff;
  letter-spacing:1.9px;
  line-height:37px;
  font-weight: 100;
}
#home .carousel .carousel-text > div {
  height: 60px;
  display: flex;
  justify-content: space-around;
}
#home .carousel .carousel-text > div p {
  width: 224px;
  background: #e60012;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.8;
}
#home .carousel .carousel-text > div p a{
  color: #fff;
}
#home .carousel .carousel-text > div p:hover {
  /* opacity: 1; */
  background: #004894
}
#home .carousel .carousel-text > div p img {
  margin-right: 10px;
  width: 20px;
}
/* 新闻 */
#home .home-news {
  width: 100%;
  overflow: hidden;  
  margin-top: 40px;
  min-width: 1440px;
}
#home .home-news .home-news-box {
  width: 1280px;
  margin: 0 auto;
  display: flex;
}
#home .home-news .home-news-carousel {
  width: 800px;
  height: 500px;
  position: relative;
}


#home .home-news .home-news-carousel .lbt-btn {
  width: 90%;
  position: absolute;
  top: 220px;
  display: flex;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
}
#home .home-news .home-news-carousel .dots-list {
  position: absolute;
  bottom: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
#home .carousel .ant-carousel .slick-slide img {
  min-width: 1440px;
  min-height: 520px;
}
#home .ant-carousel .slick-slide img {
  width: 100%;
  height: 100%;
}
#home .home-news .home-news-carousel .dots-list li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.7;
  margin: 0 6px; 
}
#home .home-news .home-news-carousel .lbt-btn img {
  cursor: pointer;
  opacity: 0.7;
}
#home .home-news .home-news-carousel .lbt-btn img:hover {
  opacity: 1;
} 
#home .home-news .home-news-carousel .ant-carousel {
  min-height: 440px;
  width: 100%;
  overflow: hidden;
}
#home .home-news .home-news-carousel .home-news-carousel-btm {
  height: 60px;
  background: #004894;
  line-height: 60px;
  color: #fff;
  text-indent: 28px;
  font-size: 18px;
}
#home .home-news .home-news-right {
  flex: 1;
  padding-left: 70px;
  display: flex;
  flex-direction: column;
}
#home .home-news .home-news-right-top, #home .home-news .home-news-right-btm {
  flex: 1;
  overflow: hidden;
}
#home .home-news .home-news-right-title {
  display: flex;
  height: 41px;
  width: 100%;
  
} 
#home .home-news .home-news-right-title > p:first-child {
  width: 83px;
  border-bottom: 2px solid #e50012;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
#home .home-news .home-news-right-title > p:last-child {
  flex: 1;
  border-bottom: 1px solid #dedede;
  margin: 0;
  font-size:14px;
  color:#999999;
  line-height: 2;
  padding-left: 10px;
}
#home .home-news .home-news-right-title > p:last-child span:last-child {   
  font-size: 16px;
  float: right;
  cursor: pointer;
}
#home .home-news .home-news-right-content {
  padding-top: 20px;
}
#home .home-news .home-news-right-content .home-news-right-item {
  display: flex;
  line-height: 46px;
  cursor: pointer;
  font-weight: 600;
}
#home .home-news .home-news-right-item:hover .news-time, #home .home-news .home-news-right-item:hover .news-title {
  color: #004894;
}
#home .news-time {
  font-size:14px;
  color:#999999;
}
#home .news-title {
  flex: 1;
  margin-left: 27px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size:14px;
  width: 100px;
  color:#333333;
}
#home .news-latest {
  display: inline-block;
  margin-top: 17px;
  width: 12px;
  height: 12px;
  background: #004894;
}
/* 便民服务 */
#home .home-convenient {
  min-width: 1440px;
  width: 100%;
  overflow: hidden;
  margin-top: 40px;
  padding-bottom: 100px;
}
#home .home-convenient-box {
  width: 1280px;
  margin: 0 auto;
} 
#home .home-convenient-list {
  width: 100%;
  display: flex;
  height: 240px;
  padding-top: 40px;
  justify-content: space-between;
}
#home .home-convenient-list a {
  width: 188px;
  height: 237px;
  display: inline-block;
}
#home .home-convenient-list .home-convenient-item {
  width: 188px;
  height: 237px;
  border-radius: 8px;
  background: #fafafa;
  border: 1px solid #d2d2d2;
  color: #333;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
#home .home-convenient-list .home-convenient-item div {
  width: 102px;
  height: 102px;
  border-radius: 50%;
  background: #004894;
  display: flex;
  justify-content: center;
  align-items: center;
}
#home .home-convenient-list .home-convenient-item span {
  margin-top: 16px;
}
#home .home-convenient-list .home-convenient-item:hover {
  background: #004894;
  border: 1px solid #004894;
  color: #fff;
}
#home .home-convenient-list .home-convenient-item:hover div {
  background: #fff;
  background-image: url(../../assets/images/book-hover.png);
  background-repeat: no-repeat;
  background-position: center;
}
#home .home-convenient-list a:nth-child(2):hover .home-convenient-item div {
  background-image: url(../../assets/images/step-hover.png);
}
#home .home-convenient-list a:nth-child(3):hover .home-convenient-item div {
  background-image: url(../../assets/images/price-hover.png);
}
#home .home-convenient-list a:nth-child(4):hover .home-convenient-item div {
  background-image: url(../../assets/images/download-hover.png);
}
#home .home-convenient-list a:nth-child(5):hover .home-convenient-item div {
  background-image: url(../../assets/images/question-hover.png);
}
#home .home-convenient-list a:nth-child(6):hover .home-convenient-item div {
  background-image: url(../../assets/images/feedback-hover.png);
}
#home .home-convenient-list a:hover .home-convenient-item div img {
  opacity: 0;
}
/* 平台 */
#home .home-platform {
  /* overflow: hidden; */
  background: #f5f5f5;
  height: 600px;
  min-width: 1440px;
}
#home .platform-content {
  background: #f5f5f5;
  width: 1280px;
  margin: 0 auto;
  display: flex;
  padding-top: 40px;
}
#home .platform-content > div {
  flex: 1;
}
#home .platform-content > div.platform-content-left {
  padding-right: 138px;
} 
#home .platform-content > div.platform-content-left .platform-list, #home .platform-content div.platform-content-right-text {
  padding-top: 32px;
}
#home .platform-content .platform-item {
  height: 120px;
  cursor: pointer;
  background: #004894;
  border-radius: 8px;
  display: flex;
  justify-content: space-around;
  margin-top: 18px;
}
#home .platform-content .platform-item:hover .platform-item-img{
  transform: translateX(80px)
}
#home .platform-content .platform-item:hover .platform-item-info{
  transform: translateX(80px)
}
#home .platform-content .platform-item-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin:0 60px;
  transition: all linear .3s;
}
#home .platform-content .platform-item-info {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 370px;
  transition: all linear .3s;
}
#home .platform-content .platform-item-info span{
  display: block
}
#home .platform-content .platform-item-info span:first-child {
  font-size: 26px;
}
#home .platform-zh {
  margin-top: 18px;
  font-size:18px;
  color:#333333;
  line-height:32px;
  font-weight: 600;
}
#home .platform-en {
  font-size:16px;
  color:#999999;
  line-height:32px;
}
/* 友链 */
#home .home-friendly {
  height: 400px;
  min-width: 1440px;
}
#home .home-friendly-top {
  overflow: hidden;
  border-bottom: 1px solid #dedede;
  padding-top: 40px;
}
#home .home-friendly-top .home-same-top {
  width: 1280px;
  margin: 0 auto;
}
#home .home-friendly-top .home-same-top p:last-child {
  border: 0;
}
#home .home-friendly-content {
  width: 1280px;
  margin: 0 auto;
}
#home .home-friendly-content-list {
  display: flex!important;
  flex-wrap: wrap;
  margin-top: 72px;
}
#home .home-friendly-content .ant-carousel .slick-dots li button {
  background: #e2e2e2;
  opacity: 1;
}
#home .home-friendly-content .ant-carousel .slick-dots li.slick-active button {
  background: #004894;
  opacity: 1;
}
#home .home-friendly-item {
  cursor: pointer;
  line-height: 74px;
  height: 74px;
  padding-bottom: 0;
  border-right: 1px solid #dedede;
  width:33%;
  margin-bottom: 28px;
  position: relative;
}

#home .home-friendly-border-right-none{
  border-right: 0;
}

#home .home-friendly-item .home-friendly-item-img {
  width: 100%;
  display: block;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#home .home-friendly-item:nth-child(1) .home-friendly-item-img {
  background-image: url('https://img.alicdn.com/imgextra/i4/O1CN01TLniVq1OvKcISjfhi_!!6000000001767-2-tps-180-30.png');
}
#home .home-friendly-item:nth-child(2) .home-friendly-item-img {
  background-image: url('https://img.alicdn.com/imgextra/i2/O1CN01oD0TKv1f3bcmx2cfx_!!6000000003951-2-tps-177-46.png');
}
#home .home-friendly-item:nth-child(3) .home-friendly-item-img {
  background-image: url('https://img.alicdn.com/imgextra/i1/O1CN01ftw05r1OZo0EgIR7k_!!6000000001720-2-tps-161-42.png');
}
#home .home-friendly-item:nth-child(4) .home-friendly-item-img {
  background-image: url('https://img.alicdn.com/imgextra/i2/O1CN01633eNs1TEnv4UnHsR_!!6000000002351-2-tps-196-51.png');
}
#home .home-friendly-item:nth-child(5) .home-friendly-item-img {
  background-image: url('https://img.alicdn.com/imgextra/i1/O1CN01SjCE6W1OR6TaAend6_!!6000000001701-2-tps-195-35.png');
}
#home .home-friendly-item:nth-child(6) .home-friendly-item-img {
  background-image: url('https://img.alicdn.com/imgextra/i3/O1CN01jmfpGG1hn4ASSYm8A_!!6000000004321-2-tps-194-46.png');
}


#home .home-friendly-item:nth-child(1) .home-friendly-item-img:hover {
  background-image: url('https://img.alicdn.com/imgextra/i3/O1CN017bXF2u1QPOE4JK8LP_!!6000000001968-2-tps-180-30.png');
}
#home .home-friendly-item:nth-child(2) .home-friendly-item-img:hover {
  background-image: url('https://img.alicdn.com/imgextra/i1/O1CN01VZfOgb1rXcyabwDCo_!!6000000005641-2-tps-177-46.png');
}
#home .home-friendly-item:nth-child(3) .home-friendly-item-img:hover {
  background-image: url('https://img.alicdn.com/imgextra/i3/O1CN01kOINoc1NPtoqTUXhp_!!6000000001563-2-tps-161-42.png');
}
#home .home-friendly-item:nth-child(4) .home-friendly-item-img:hover {
  background-image: url('https://img.alicdn.com/imgextra/i4/O1CN01KT7LBu1P4ww2Ft56q_!!6000000001788-2-tps-196-51.png');
}
#home .home-friendly-item:nth-child(5) .home-friendly-item-img:hover {
  background-image: url('https://img.alicdn.com/imgextra/i4/O1CN017WKGUR1PYGHYB0Biz_!!6000000001852-2-tps-195-35.png');
}
#home .home-friendly-item:nth-child(6) .home-friendly-item-img:hover {
  background-image: url('https://img.alicdn.com/imgextra/i2/O1CN01zQkMtS1MvDHY7vmC3_!!6000000001496-2-tps-194-46.png');
}

#home .home-friendly-item .border-btm {
  position: absolute;
  left: 50%;
  bottom: -15px;
  transform: translateX(-50%);
  width: 86%;
  height: 2px;
  background: #dedede;
}
#home .no-border {
  border: 0;
}
/* 位置 */
#home .home-adress {
  min-height: 462px;
  background: #dcdcdc;
  min-width: 1440px;
  background: url(../../assets/images/map.png) no-repeat center;
}
#home .home-adress-content {
  width: 1280px;
  margin: 0 auto;
  padding-top: 82px;
}
#home .adress-icon {
  height: 75px;
  text-align: center;
  line-height: 50px;
  position: relative;
}
#home .adress-icon span {
  position: absolute;
  color: #fff;
  left: 46%;
  transform: translateX(-50%);
}
#home .adress-icon img.img-point {
  position: absolute;
  top: 0;
  left: 46%;
  transform: translateX(-50%);
}
#home .adress-icon img.img-quan {
  position: absolute;
  bottom: 0;
  left: 46%;
  transform: translateX(-50%);
}
#home .home-adress-info {
  margin-top: 19px;
  display: flex;
}
#home .home-adress-info div {
  flex: 1;
  font-size:16px;
  color:#666666;
  letter-spacing:1.14px;
  font-weight: 600;
}
#home .home-adress-info div.adress-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
#home .home-adress-info div.adress-right p {
  display: inline-block;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;  
  margin-left: 63px;
}
#home .home-adress-info div.adress-right p span {
  margin-top: 15px;
  font-size: 16px;
}
#home .home-adress-info div.adress-right img {
  padding: 5px;
  border: 2px solid #004894;
}
/* 举报弹窗 */
#home .report {
  position: fixed;
  background:#ffffff;
  border:1px solid #004894;
  width:330px;
  /* height:270px; */
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
#home .report .report-header {
  background:#004894;
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size:16px;
  color:#ffffff;
  letter-spacing:1.14px;
}
#home .report .report-header i {
  float: right;
  margin-top: 12px;
  margin-right: 20px;
  font-size: 18px;
  cursor: pointer;
}
#home .report .report-content {
  flex: 1;
  padding: 19px 24px;
}
#home .report .report-content > div {
  display: flex;
  font-size: 14px;
  color:#333333;
  letter-spacing:1.14px;
  text-align:left;
  line-height: 18px;
  margin-bottom: 10px;
}

#home .report .report-content > div span:last-child {
  flex: 1
}