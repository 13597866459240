#drop-menu {
  position: absolute;
  min-width: 100%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  top: 76px;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: all linear 0.3s;
  padding: 0 10px;
}
#drop-menu > a {
  line-height: 40px;
  color: #333;
  display: block;
  white-space: nowrap;
}
#drop-menu > a:hover {
  color: #e50012;
} 