#complaints {
  flex: 1;
  padding-left: 70px;
  padding-top: 5px;
  padding-right: 5px;
}
#complaints > div {
  display: flex;
  margin-bottom: 30px;
}
#complaints > div > span {
  /* width: 72px; */
  font-size:16px;
  color:#333333;
  letter-spacing:-0.22px;
  margin-right: 20px;
  font-weight: 600;
  text-align: justify;
  display: block;
}
#complaints > div > span i{
  display:inline-block;
  /*padding-left: 100%;*/
  width:100%;
}
#complaints > div input {
  width: 376px;
  height: 38px;
  line-height: 38px;
  border-radius: 20px;
  background:#fafafa;
  border:1px solid #e0e0e0;
}
#complaints > div .text-area-content {
  flex: 1;
  position: relative;
}
#complaints > div.code-box > div input{
  width: 150px;
}
#complaints > div.code-box > div > span {
  user-select:none;
  padding: 0 20px;
}
#complaints > div.code-box > div button {
  width: 90px;
}
#complaints > div .text-area-content p {
  position: absolute;
  right: 10px;
  bottom: 10px;
  margin: 0;
}
#complaints > div textarea {
  width: 100%;
  height: 364px;
  background:#fafafa;
  border:1px solid #e0e0e0;
  border-radius:4px;
  resize: none;
}
#complaints button {
  width: 230px;
  height: 40px;
  border-radius: 20px;
}