#search-result .search-content {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #d1d1d1;
}
#search-result .search-box {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}
#search-result .result-red-text {
  color: #e50012;
}
#search-result .result-info {
  font-size: 16px;
  line-height: 80px;
}
#search-result .result-list {
  width: 100%;
  overflow: hidden;
}
#search-result .result-list > a {
  display: block;
  overflow: hidden;
  margin-bottom: 14px;
}
#search-result .ant-pagination  {
  width: 100%;
  margin: 0 auto;
  height: auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 77px;
  margin-top: 27px;
}
#search-result  .result-item {
  height: 82px;
  border: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
}
#search-result .result-list span.item-pointer{
  width: 12px;
  height: 12px;
  background: #999;
  margin-left: 25px;
  margin-right: 16px;
  display: block;
}
#search-result .result-list li.liInfo {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
}
#search-result .result-list li.infoTime{
  font-size: 16px;
  color:#999999;
  letter-spacing:-0.2px;
  text-align:left;
  margin-right: 40px;
}
#search-result  .result-item:hover {
  background: #004894;
}
#search-result  .result-item:hover  li {
  color: #ffffff;
}
#search-result  .result-item:hover span {
  background: #fff;
}