#side-bar {
  width: 320px;
  overflow: hidden;
  margin-bottom:112px;
}
#side-bar .side-header {
  height: 118px;
  background: #004894;
  padding: 22px 22px 22px 27px;
}
#side-bar .side-header-top {
  display: flex;
  color: #fff;
  font-size: 26px;
  letter-spacing:-0.31px;

} 
#side-bar  p {
  margin: 0;
}
#side-bar .side-header-top p:first-child {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
#side-bar .side-header-top p:first-child span.white-border {
  display: inline-block;
  height: 1px;
  background: #fff;
  width: 100%;
}
#side-bar .side-header > p {
  opacity:0.45;
  font-size: 18px;
  color:#ffffff;
  letter-spacing: -0.22px;
}
#side-bar .side-list {
  background: #fafafa;
  padding-left: 34px; 
  padding-right: 48px; 
}
#side-bar .side-item {
  height: 85px;
  border-bottom: 1px dashed #cdcdcd;  
}
#side-bar .side-item.selected > a{
  color: #004894;
}
#side-bar .side-item:last-child {
  border-bottom: 0;
}
#side-bar .side-item > a {
  display: inline-block;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color:#999999;
  letter-spacing:-0.22px;
  font-weight: 600;
}
#side-bar .side-item > a span:first-child {
  font-size:20px;
}
#side-bar .side-item > a span {
  display: block;
}
#side-bar .side-item > a span:last-child {
  font-size:14px;  
}
#side-bar .side-item > a:hover, #side-bar .side-item > a.selected {
  color: #004894;
}