#header {
  width: 100%;
  /* overflow: hidden; */
  height: 120px;
}
/* 页面最顶部 */
#header .header-top {
  background:#f5f5f5;
  width: 100%;
  height: 40px;
  min-width: 1440px;
}
#header .header-top-content {
  width: 1280px;
  height: 100%;
  margin: 0 auto;
  line-height: 40px;
  font-size: 14px;
  color:#999999;
}
#header  .header-top-content .ant-input-group-wrapper {
  float: right;
  border-radius: 16px;
  width: 218px;
  margin-top: 4px;
}
#header  .header-top-content .ant-input-group-wrapper input {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-right: 0;
}
#header  .header-top-content .ant-input-group-wrapper .ant-input-group-addon {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-left: 0;
  position: relative;
  cursor: pointer;
}
#header  .header-top-content .ant-input-group-wrapper .ant-input-group-addon::before {
  content: '';
  position: absolute;
  width: 1px; 
  height: 20px;
  left: 0;
  background: #d1d1d1;
  top: 50%;
  transform: translateY(-50%)
}
#header .header-top-content .header-welcome {
  padding-left: 13px;
  float: left;
  margin: 0;
}
#header .header-top-content .header-right {
  float: right;
  margin: 0;
}
#header .header-top-content .header-right span {
  padding-left: 15px;
  cursor: pointer;
}
#header .header-top-content .header-right span:first-child {
  color: #333;
  border-right: 1px solid #d1d1d1;
  padding-right: 15px;
}
/* 头部导航 */
#header .header-nav {
  height: 80px;
  min-width: 1440px;
}
#header .header-nav-box {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  height: 80px;
}
#header .header-nav .header-logo{
  width: 360px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#header .header-nav .header-logo img {
  width: 250px;
}
#header .header-nav .header-nav-list {
  flex: 1;
  margin-left: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#header .header-nav .header-nav-list > div {
  /* flex: 1; */
  line-height: 77px;
  text-align: center;
  border-top: 3px solid transparent;
  margin: 0;
  position: relative;
}
/* #header .header-nav .header-nav-list > div:first-child {
  border-top: 0;
} */
#header .header-nav .header-nav-list > div:hover {
  border-top-color: #e50012;
}
#header .header-nav .header-nav-list > div:hover > div {
  opacity: 1;
  z-index: 1;
  display: block;
} 
#header .header-nav .header-nav-list > div:hover > a, #header .header-nav .header-nav-list > div.selected > a{
  color: #e50012;
  font-weight: 700;
}
#header .header-nav .header-nav-list > div > a {
  font-size: 16px;
  color:#333;
}
.add{
  width: 300px !important;
}