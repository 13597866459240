
#bannerUrl{
    min-width: 1440px;
    position: relative;
    margin-bottom: 40px;
    height: 475px;
    overflow: hidden;
}
#bannerUrl .bannerBox{
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    left:50%;
    top: 50%;
    height:136px;
}
#bannerUrl .fontCn{
    font-size:72px;
    color:#ffffff;
}
#bannerUrl .fontEn{
    font-size:24px;
    color:#ffffff;
    letter-spacing:1.9px;
    line-height:37px;
    text-align:center;
}
/* banner图 */
#bannerUrl .carousel {
    width: 100%;
    height: 520px;
    overflow: hidden;
    position: relative;
    min-width: 1440px;
  }
  #bannerUrl .carousel .ant-carousel{
    height: 100%;
    width: 100%;
    background: #364d79;
    /* overflow: hidden; */
  }
  #bannerUrl .carousel .ant-carousel .carousel-img-content {
    vertical-align: bottom;
  }
  #bannerUrl .carousel .lbt-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 50px;
    width: 1300px;
    left: 50%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  #bannerUrl .carousel .lbt-btn img {
    opacity: 0.7;
  }
  #bannerUrl .carousel .lbt-btn img:hover {
    opacity: 1;
  }