#footer {
  height: 440px;
  /* width: 100%; */
  min-width: 1440px;
}
#footer .footer-top {
  height: 380px;
  /* width: 100%; */
  margin: 0 auto;
  background:#4a4a4a;
}
#footer .footer-bottom {
  height: 66px;
  width: 100%;
  margin: 0 auto;
  background:#3b3b3b;
  overflow: hidden;
}
#footer .footer-top .footer-top-content {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  padding-top: 50px;
}
#footer .footer-top .footer-top-content > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
#footer .footer-top .footer-top-content > div p {
  color: #fff;
  font-size: 14px;
}
#footer .footer-top .footer-top-content > div span {
  display: block
}
#footer .footer-top .footer-top-content > div span a {
  color: #fff;
  opacity:0.6;
  font-size: 14px;
  line-height: 30px;
}
#footer .footer-top .footer-top-content > div span a:hover {
  opacity: 1;
} 
#footer .footer-top .footer-top-content > div:last-child p.last-child {
  margin-top: 40px;
}
#footer .footer-bottom .footer-bottom-content {
  width: 1380px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 13px;

}
#footer .footer-bottom .footer-bottom-content p {
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
}