* {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: #333;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
li,
ul,
dl,
dd,
dt {
  list-style: none;
}
/* 修改antd的tabs切换宽度 */
.ant-tabs {
  width: 842px;
  float: right;
}

.banner-hide {
  display: none !important;
}
#_ideConac {
  display: block;
  width: 100%;
  text-align: center;
}
