#common.pagination{
  width: 100%;
  margin:0 auto;
  height:auto;
  display:flex;
  justify-content: flex-end;
  margin-bottom:77px;
  margin-top:27px;
}
.allSameBox {
  overflow: hidden;
  padding-bottom: 30px;
}
/* #guide .ant-pagination-item-active{
  background:#2a8ff6;
}
#guide .ant-pagination-item-active a{
  color:#ffffff !important;
} */