#ChargeFastCalculate .allSameBox{
    height:538px;
    width:785px;
    background:#fafafa;
    border:1px solid #e0e0e0;
    border-radius:8px;
    width:783px;
    height:536px;
}
#ChargeFastCalculate .countButton{
    background:#ffffff;
    border:1px solid #2a8ef6;
    border-radius:100px;
    width:416px;
    height:38px;
    display:flex;
    overflow: hidden;
}
#ChargeFastCalculate .countBox{
    width:auto;
    height:40px;
    display:flex;
    justify-content: space-around;
    line-height:40px;
    margin-bottom:24px;
}
#ChargeFastCalculate .countFont{
    font-size:18px;
    color:#333333;
    letter-spacing:-0.22px;
    text-align:left;
    width:200px;
}
#ChargeFastCalculate .tab1{
    height:40px;
    width:210px;
}
#ChargeFastCalculate .allSameBox{
    padding:45px 85px 23px 85px;
    float: right;
}
#ChargeFastCalculate .countInput{
    width:90%;
    outline:none;
    border:0;
    margin:0 auto;
    height:35px;
}
#ChargeFastCalculate .changeButton{
    border-radius:100px;
    width:210px;
    height:36px;
    outline:none;
    border:none;
    cursor: pointer;
    font-size:18px;
    color:#666666;
    letter-spacing:-0.22px;
    line-height:36px;
    background: #fff;
}
#ChargeFastCalculate .changeButtonActive{
    background:#8ec0f4;
    font-size:18px;
    color:#ffffff;
    letter-spacing:-0.22px;
    position: relative;
}
#ChargeFastCalculate .changeButtonActive::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 45%;
    transform: translateY(-50%) rotate(-45deg);
    width: 20px;
    height: 10px;
    border-bottom: 2px solid #98FB98;
    border-left: 2px solid #98FB98;
}

#ChargeFastCalculate .btn{
    display:flex;
    height:40px;
    width:auto;
    justify-content: space-between;
    margin-bottom:62px;
}
#ChargeFastCalculate .numButton{
    background:#ffffff;
    border-radius:100px;
    width:278px;
    height:40px;
    outline:none;
    border:1px solid #2a8ef6;
    text-align:center;
    color:#2a8ef6;
    cursor: pointer;
}
#ChargeFastCalculate .onnum{
    background:#2a8ef6;
    color:#ffffff;
}
#ChargeFastCalculate .countResult{
    font-size:20px;
    color:#333333;
    letter-spacing:-0.25px;
    text-align:left;
}
#ChargeFastCalculate .solid{
    border:1px solid #e0e0e0;
    width:783px;
    height:2px;
    margin-left:-85px;
    margin-bottom:24px;
}
#ChargeFastCalculate .myAllCount{
    display:flex;
    justify-content: space-between
}
#ChargeFastCalculate .clildmyCount p:nth-of-type(1){
    font-size:18px;
    color:#333333;
    letter-spacing:-0.22px;
    text-align:left;
}
#ChargeFastCalculate .clildmyCount p:nth-of-type(2){
    font-size:36px;
    color:#004894;
    letter-spacing:-0.44px;
    text-align:left;    
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
 
input[type="number"] {
    -moz-appearance: textfield;
}