/* 去除表格默认样式 */
#library .rowrua:nth-of-type(even){
  background:#FAFAFA;
}
#library .rowrua:nth-of-type(odd){
  background:#FFFFFF;
}
#library .ant-table-thead tr th:nth-last-of-type(1){
  border-right:none;
}
#library .ant-table-row-level-0 td:nth-last-of-type(1){
  border-right:none !important;
}
#library .ant-table-body table{
  border:none !important;
}
#library .ant-table-row-level-0 td{
  border-bottom:none !important;
}
#library .ant-table-thead tr th{
  font-weight: 600;
  color:#333333 !important;
  background: #eae9e9;
}
#library .ant-table-bordered .ant-table-thead > tr > th,#library  .ant-table-bordered .ant-table-tbody > tr > td {
  border-color: #f2f2f2 !important;
}

#library .ant-select-arrow .ant-select-arrow-icon, #library .ant-input-search-icon {
  color: #004894;
  font-size: 18px;
}

#library {
  margin-left: 70px;
}

#library .label{
  height:40px;
  line-height:40px;
  margin-right:14px;
  font-size:16px;
  color:#333333;
  font-weight: 600;
}
#library .ant-select-selection-selected-value{
  font-size:16px;
  color:#004894;
}
#library .ant-select-selection--single{
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-color: #004894 !important;
  border-right: none !important;
}
#library .ant-select-lg.selectBox.ant-select.ant-select-enabled:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 22px;
  background: #d2d2d2;
  right: 0;
  top: 9px;
}
#library .ant-input{
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important; 
  border-color: #004894 !important;
  border-left: none !important;
  outline: none !important;
}
#library .ant-input-group.ant-input-group-compact > span:not(:last-child) > .ant-input{
  border-right-width: 1px !important;
}
#library .buttonBox{
  background:#004894;
  border-radius:100px;
  width:99px;
  height:40px;
  font-size:16px;
  color:#ffffff;
  text-align:left;
  margin-left:20px;
}