@charset "UTF-8";
@font-face {
  font-family: "KaiTi_GB2312";
  src:url('../../fronts/KaiTi_GB2312.ttf');
}
@font-face {
  font-family: "SimHei";
  src:url('../../fronts/SimHei.ttf');
}
@font-face {
  font-family: "NSimSun";
  src:url('../../fronts/Nsimsun.ttf');
}
@font-face {
  font-family: "PMingLiU";
  src:url('../../fronts/PMingLiU.ttf');
}
@font-face {
  font-family: "FangSong";
  src:url('../../fronts/FangSong.ttf');
}
@font-face {
  font-family: "FangSong_GB2312";
  src:url('../../fronts/FangSong_GB2312.ttf');
}
@font-face {
  font-family: "SimSun";
  src:url('../../fronts/SimSun.ttf');
}
@font-face {
  font-family: "Microsoft YaHei";
  src:url('../../fronts/MicrosoftYaHei.ttf');
}

#details {
  margin-left: 35px;
}
#details h3.title {
  font-size:20px;
  color:#333333;
  letter-spacing:-0.2px;
}
#details .publish-time {
  font-size:14px;
  color:#999999;
  letter-spacing:-0.2px;
  line-height: 25px;
  margin: 0;
}
#details .publish-time span:first-child {
  margin-right: 20px;
} 
#details .publish-time span:last-child {
  color: rgb(97, 169, 81)
}
#details .line-dashed {
  height: 1px;
  border-top: 1px dashed #979797;
  margin-top: 27px;
}
#details .details-content {
  padding-top: 30px;
}
#details .back-btn {
  text-align: center;
  padding: 60px 0;
}
#details .back-btn button {
  width: 118px;
  height: 40px
}
#details .details-content img {
  max-width: 100% !important;
}

#details .ant-spin-container .details-content {
  font-size: 20px;
}
.video-wrap video{
  width: 100%;
}

.img img {
  width: 100%;
}
